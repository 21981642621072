import request from "@/utils/request"

// 获取工作台数据
export function getCockpitData(data) {
  return request({
    url: '/workbench/getDataStatisticsResult',
    method: 'get',
    params: data
  })
}

// 客户数据折线图
export function getUserCurveChart(data) {
  return request({
    url: '/workbench/getUserCurveChart',
    method: 'get',
    params: data
  })
}

// 获取用户数据
export function getServiceDataResult(data) {
  return request({
    url: '/workbench/getServiceDataResult',
    method: 'get',
    params: data
  })
}
//走访次数柱状图
export function getVisitResult(data) {
  return request({
    url: '/workbench/getVisitResult',
    method: 'get',
    params: data
  })
}
//走访需求次数柱状图
export function getVisitRequirementResult(data) {
  return request({
    url: '/workbench/getVisitRequirementResult',
    method: 'get',
    params: data
  })
}
//案例数据柱状图
export function getPlanResult(data) {
  return request({
    url: '/workbench/getPlanResult',
    method: 'get',
    params: data
  })
}
