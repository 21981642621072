<template>
  <div class="workbench">
    <div class="top">
      <div class="login_info">
        <img src="../../assets/image/Group 2821.png" alt="" />
        <div class="info">
          <h2>HI，欢迎登录{{ zcName }}管理后台</h2>
          <div class="time">
            <span class="info_icon">
              <i class="el-icon-refresh"></i>
            </span>
            <span>最新更新时间：{{ cockpitData.time }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="dataTxt">
      <ul >
        <li @click="companyClick()">
          <div class="icon">
            <img width="70" height="70" src="../../assets/image/Group 1142815023.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">客户数据（个）</span>
            <div class="numInfo">
              <span class="num">{{
                cockpitData.companyNum == null ? 0 : cockpitData.companyNum
              }}</span>
              <span>
                <span class="add">本周新增</span>
                <span class="addNum">{{
                  cockpitData.companyWeekNum == null ? 0 : cockpitData.companyWeekNum
                }}</span>
                <span class="addIcon">
                  <i class="el-icon-top"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li @click="cockpitClick()">
          <div class="icon">
            <img width="70" height="70" src="../../assets/image/Group 1142814542.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">客户经理（个）</span>
            <div class="numInfo">
              <span class="num">{{ cockpitData.userNum == null ? 0 : cockpitData.userNum }}</span>
              <span>
                <span class="add">本周新增</span>
                <span class="addNum">{{
                  cockpitData.userWeekNum == null ? 0 : cockpitData.userWeekNum
                }}</span>
                <span class="addIcon">
                  <i class="el-icon-top"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="icon">
            <img width="70" height="70" src="../../assets/image/Group 1142814742.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">走访次数（次）</span>
            <div class="numInfo">
              <span class="num">{{ cockpitData.visitNum == null ? 0 : cockpitData.visitNum }}</span>
              <span>
                <span class="add">本周新增</span>
                <span class="addNum">{{
                  cockpitData.visitWeekNum == null ? 0 : cockpitData.visitWeekNum
                }}</span>
                <span class="addIcon">
                  <i class="el-icon-top"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="icon">
            <img width="70" height="70" src="../../assets/image/Group 1142815037.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">走访需求（个）</span>
            <div class="numInfo">
              <span class="num">{{ cockpitData.visiRequirementNum == null ? 0 : cockpitData.visiRequirementNum }}</span>
              <span>
                <span class="add">本周新增</span>
                <span class="addNum">{{
                  cockpitData.visitRequirementWeekNum == null ? 0 : cockpitData.visitRequirementWeekNum
                }}</span>
                <span class="addIcon">
                  <i class="el-icon-top"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="icon">
            <img width="70" height="70" src="../../assets/image/Group 1142814504.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">预算费用（万）</span>
            <div class="numInfo">
              <span class="num">{{ cockpitData.totalBudget == null ? 0 : cockpitData.totalBudget }}</span>
              <span>
                <span class="add">本周新增</span>
                <span class="addNum">{{
                  cockpitData.weekBudget == null ? 0 : cockpitData.weekBudget
                }}</span>
                <span class="addIcon">
                  <i class="el-icon-top"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li @click="companyClick('3')">
          <div class="icon">
            <img src="../../assets/image/Group 1142815038.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">潜在线索（个）</span>
            <div class="numInfo">
              <span class="num">{{
                cockpitData.diagnosisNum == null ? 0 : cockpitData.diagnosisNum
              }}</span>
              <span>
                <span class="add">本周新增</span>
                <span class="addNum">{{
                  cockpitData.diagnosisWeekNum == null ? 0 : cockpitData.diagnosisWeekNum
                }}</span>
                <span class="addIcon">
                  <i class="el-icon-top"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li @click="companyClick('1')">
          <div class="icon">
            <img src="../../assets/image/Group 1142814997.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">服务记录（个）</span>
            <div class="numInfo">
              <span class="num">{{
                cockpitData.serviceNum == null ? 0 : cockpitData.serviceNum
              }}</span>
              <span>
                <span class="add">本周新增</span>
                <span class="addNum">{{
                  cockpitData.serviceWeekNum == null ? 0 : cockpitData.serviceWeekNum
                }}</span>
                <span class="addIcon">
                  <i class="el-icon-top"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
        <li @click="companyClick('2')">
          <div class="icon">
            <img src="../../assets/image/Group 1142815034.png" alt="" />
          </div>
          <div class="data_info">
            <span class="title">案例数据（个）</span>
            <div class="numInfo">
              <span class="num">{{ cockpitData.planNum == null ? 0 : cockpitData.planNum }}</span>
              <span>
                <span class="add">本周新增</span>
                <span class="addNum">{{
                  cockpitData.planWeekNum == null ? 0 : cockpitData.planWeekNum
                }}</span>
                <span class="addIcon">
                  <i class="el-icon-top"></i>
                </span>
              </span>
            </div>
          </div>
        </li>
      </ul>
     </div>
    <div class="dataEcharts">
      <chart1></chart1>
      <chart2></chart2>
  
    </div>
    <div class="dataEcharts">
      <chart3></chart3>
      <chart4></chart4>
  
    </div>
  </div>
</template>

<script>

import { getCockpitData,  } from '../../api/cockpit.js'
import chart1 from './components/chart1.vue'
import chart2 from './components/chart2.vue'
import chart3 from './components/chart3.vue'
import chart4 from './components/chart4.vue'
export default {
  name: 'cockpit',
  components: { chart1,chart2 ,chart3,chart4},
  data() {
    return {
      // 工作台数据
      cockpitData: {
        companyNum: null,
        companyMonthNum: null,
        userNum: null,
        userMonthNum: null,
        serviceNum: null,
        serviceMonthNum: null,
        diagnosisNum: null,
        diagnosisMonthNum: null,
        orderNum: null,
        orderMonthNum: null,
        time: null,
        totalIncome: null,
        associateOrderNum: null,
        reportSuccessNum: null,
        reportIngNum: null,
        todayDiagnoseNum: null
      },
     
    
    }
  },
  computed: {
    // id
    partnerId() {
      return JSON.parse(sessionStorage.getItem('typeMode')).id
    },
    // 判断当前是使用的什么智参（浏阳、佛山、合作伙伴）
    zcName() {
      return JSON.parse(sessionStorage.getItem('zcName'))
    },
    roleId() {
      return JSON.parse(sessionStorage.getItem('roleId'))
    },
    customerId() {
      return sessionStorage.getItem('customerId')
    }
  },
  created() {
    this.getCockpitData() //获取工作台数据
  },
  mounted() {
   
  },
  destroyed() {
    
  },
  methods: {
   
    //客户企业
    companyClick(isred) {
      this.$router.push({
        path: '/enterpriseCustomer',
        query: {
          isred
        }
      })
    },
    cockpitClick() {
      this.$router.push({
        path: '/customermanager'
      })
    },

    // 获取工作台数据
    async getCockpitData() {
      const res = await getCockpitData({
        // partnerId: this.partnerId,
        id: this.customerId || ''
      })
      if (res.resultCode == 200) {
        this.cockpitData = res.data
      }
    },
 
   
  
   
  
    // 跳转诊断服务
    toDiagnosis() {
      this.$router.push({
        name: 'diagnosis'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.active {
  border: 1px 0px 0px 0px;
  background: #85b6ff;
  color: #fff;
  cursor: pointer;
}
.workbench {
  width: 100%;
  height: 100%;
  background: #f6f8f9;
  box-sizing: border-box;
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  // img {
  //   width: 303px;
  //   height: 303px;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  // .workbench_trips {
  //   position: absolute;

  //   color: #999999;
  //   font-size: 18px;
  //   top: 67%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 12%;
    background: #fff;
    font-family: PingFang SC;
    border-radius: 4px;
    margin-bottom: 1%;
    .login_info {
      display: flex;
      align-items: center;
      padding-left: 16px;
      img {
        width: 78px;
        height: 78px;
        border-radius: 50%;
        margin-right: 24px;
        display: flex;
        align-items: center;
      }
      .info {
        height: 78px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 4px 0;
        box-sizing: border-box;
        .info_icon {
          width: 16px;
          height: 16px;
          color: #8ab8ff;
          margin-right: 6px;
        }
      }
      .info h2 {
        font-size: 24px;
        font-weight: 500;
        color: #151515;
        padding: 0;
        margin: 0;
      }
      .time span:nth-child(2) {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: left;
        color: #595959;
      }
    }
    .right {
      height: 78px;
      display: flex;
      .income {
        height: 100%;
        margin-right: 60px;
        .income_title {
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
          text-align: left;
          color: #ff6b00;
          :nth-child(1) {
            width: 13px;
            height: 13px;
          }
        }
        .income_money {
          font-size: 12px;
          font-weight: 600;
          line-height: 16.8px;
          text-align: left;
          color: #ff6b00;
          .money_icon {
            font-size: 28px;
            font-weight: 600;
            line-height: 44.8px;
            text-align: left;
          }
        }
        .income_order {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.8px;
          text-align: left;
          color: #999;
        }
      }
      .reporting_success {
        height: 100%;
        margin-right: 60px;
        color: #09121f;
        .reporting_title {
          .report_icon {
            font-size: 13px;
          }
          .report_title {
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
          }
        }
        .reporting_num {
          font-size: 12px;
          font-weight: 600;
          line-height: 16.8px;
          span {
            font-size: 28px;
            font-weight: 600;
            line-height: 44.8px;
          }
        }
        .reporting_order {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.8px;
          color: #999;
        }
      }
    }
  }
  .dataTxt {
    height: 25%;
    ul {
      width: 100%;
      height: 100%;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
      gap: 16px; /* 根据需要调整间距 */
      li {
        padding: 20px 0 20px 20px;
        display: flex;
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
        .icon {
          width: 61px;
          height: 61px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 5px;
          margin-right: 20px;
          i {
            // width: 32px;
            // height: 28px;
            font-size: 32px;
          }
        }
        .data_info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 16px;
            font-weight: 550;
            color: #333;
          }
          .numInfo {
            .num {
              font-size: 24px;
              font-weight: 600;
              line-height: 33.6px;
              color: #333;
              margin-right: 12px;
            }
            .add {
              font-size: 12px;
              font-weight: 400;
              line-height: 16.8px;
              color: #ff6c66;
            }
            .addNum {
              font-size: 16px;
              font-weight: 400;
              line-height: 22.4px;
              color: #ff6c66;
              margin-left: 6px;
            }
            .addIcon {
              color: #ff6c66;
              margin-left: 6px;
              i {
                font-size: 17px;
              }
            }
          }
          .numInfo:hover {
            .num {
              color: #448aff;
            }
          }
        }
      }
    }
    ul li:nth-child(3) .icon {
      background: #f4b667;
    }
    ul li:nth-child(4) .icon {
      background: #9a7bf2;
    }
    ul li:nth-child(5) .icon {
      background: #609bf0;
    }
  }
  .dataEcharts {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
