<template>
  <div class="workbench">
    <div class="dataEcharts">
      <div class="userData">
        <div class="data_title">
          <div class="data">走访需求</div>
          <div class="date">
            <div class="month" :class="{ active: isActive3 }" @click="checkMonthEchart1">年</div>
            <div class="day" :class="{ active: isActive2 }" @click="checkDayEchart1">近15天</div>
          </div>
        </div>
        <div class="data_echarts" ref="echarts1"></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
// 引入echarts
import * as echarts from 'echarts'
import { getVisitRequirementResult } from '../../../api/cockpit.js'
export default {
  name: 'cockpit',
  data() {
    return {
      isActive1: false,
      isActive2: true,
      isActive3: false,
     
      chart1: 2, //折线图1（2-月, 1-天）
      chart2: 1, //折线图2（2-月, 1-天）
      chart1XAxis: [], //折线图1的x轴数据
      chart1Series: [], //折线图1的series数据
      chart2XAxis: [], //折线图2的x轴数据
      chart2Series: [], //折线图2的series数据
      chart1XAxisShowMd: [], // 折线图1的x轴处理显示数据是月还是月+天
      chart2XAxisShowMd: [], //  折线图2的x轴处理显示数据是月还是月+天
      chartArr1: [],
      timer: null,
      chart1DefaultData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //用户折线图默认数据
      chart2DefaultData: [
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        },
        {
          industryNum: 0,
          personalNum: 0,
          planNum: 0,
          planVideoNum: 0,
          policyNum: 0,
          productNum: 0,
          sceneKnowledgeNum: 0,
          sceneNum: 0,
          serverNum: 0,
          techNum: 0
        }
      ], //服务折线图默认数据
      chartArr2: [],
      chart1Flag: false // 判断用户折线图使用有数据
    }
  },
  computed: {
    // id
    partnerId() {
      return JSON.parse(sessionStorage.getItem('typeMode')).id
    },
    // 判断当前是使用的什么智参（浏阳、佛山、合作伙伴）
    zcName() {
      return JSON.parse(sessionStorage.getItem('zcName'))
    },
    roleId() {
      return JSON.parse(sessionStorage.getItem('roleId'))
    },
    customerId() {
      return sessionStorage.getItem('customerId')
    }
  },
  created() {},
  mounted() {
    this.getUserCurveChart(this.chart1) //动态获取折线图1的数据
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    // 折线图1
    renderEcharts1() {
      let echarts1 = this.$refs.echarts1
      let myChart = echarts.init(echarts1)
      let type = this.chart1
      let option

      // 所有的数据
      const chartArr = this.chartArr1
      const chart1Flag = this.chart1Flag

      option = {
        // 可以显示整个图表
        dataZoom: [
          {
            type: 'slider', // inside 内置型  slider 滑动条
            show: true,
            start: 0,
            end: 60
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: 10,
            end: 60
          }
        ],
        title: {
          subtext: '（需求数/个）', // 副标题文本
          left: 'left', // 标题水平居中
          top: 14,
          textStyle: {
            // 标题文本样式
          },
          subtextStyle: {
            // 副标题样式
            fontStyle: 'normal', // 字体风格
            fontWeight: 'bold', // 字体粗细
            fontSize: 12, // 字体大小，可以根据需要调整
            color: '#333333' // 字体颜色
          }
        },
        color: '#4E93FB',
        // 实现 echarts 鼠标悬浮上去的效果
        tooltip: {
          trigger: 'axis',
          show: true, // 是否显示提示框，默认为 true
          // trigger: 'item', // 触发类型，可选值: 'item'（数据项触发），'axis'（坐标轴触发），'none'（不触发）
          backgroundColor: '#fff', // 提示框背景色
          padding: [3], // 内边距
          textStyle: {
            // 文本样式
            color: '#fff', // 文本颜色
            fontSize: 12 // 文本字号
          },
          // 悬浮框提示
          formatter(params) {
            if (!chart1Flag) {
              return
            }
            // console.log(params);

            const userData = chartArr[params[0].dataIndex]
            const arr = userData.time.split('-')
            // console.log(arr);

            // 处理时间展示格式
            const time = arr[2] ? `${arr[0]}年${arr[1]}月${arr[2]}日` : `${arr[0]}年${arr[1]}月`
            return `
              <div style="width:145px; height:68px; color:#fff; background: #fff; font-family: PingFang SC; padding: 10px; box-sizing: border-box;">
                <div style="display:flex; margin-bottom: 4px">
                     <img width="32" height="32" src=" https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-949922307Group 1142815037.png
              " alt="" />
                 
                  <div style="display:flex; flex-direction: column; justify-content: space-between; margin-left: 8px">
                    <span style="font-size: 10px; color: #333; font-weight: 600;">${userData.time}需求数</span>
                    <span style="font-size: 10px; color: #333; font-weight: 400;">${userData.num}</span>
                  </div>
                </div>
  
              </div>`
          },
          // 辅助线
          axisPointer: {
            type: 'line', // 指示器类型，可选值: 'line'（直线指示器），'shadow'（阴影指示器），'cross'（十字准星指示器）
            lineStyle: {
              color: '#1890FF', // 辅助线颜色
              type: 'dashed'
            },
            crossStyle: {
              // 十字准星指示器样式设置
              color: '#aaa' // 线条颜色
            },
            shadowStyle: {
              // 阴影指示器样式设置
              color: 'rgba(150,150,150,0.3)' // 阴影颜色
            }
          }
        },
        xAxis: {
          type: 'category',
          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: '#333333' //Y轴内容文字颜色
            },
            interval: 0 // 设置为0表示全部显示x轴坐标
            // data: this.chartArr1.map((item) => {
            //   item.time
            // }),
            // formatter: function (params) {
            //   // var item = this.chart1XAxisShowMd
            //   // console.log(item, 'item111')
            //   // return this.chart1XAxisShowMd
            // }
          },
          boundaryGap: false,
          nameTextStyle: {
            verticalAlign: 'bottom',
            lineHeight: 10,
            backgroundColor: '#DCE9FE'
          },
          // data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
          // data: ['12', '11', '10', '9', '8', '7', '6', '5', '4', '3', '2', '1']
          // data: this.chart1XAxisShowMd,
          // data:
          //   type == 1
          //     ? [
          //         '1月',
          //         '2月',
          //         '3月',
          //         '4月',
          //         '5月',
          //         '6月',
          //         '7月',
          //         '8月',
          //         '9月',
          //         '10月',
          //         '11月',
          //         '12月'
          //       ]
          //     : this.chart1XAxisShowMd
          data: this.chart1XAxisShowMd
        },
        yAxis: {
          type: 'value',
          data: [0, 4000, 8000, 12000, 16000, 20000, 24000],
          axisLine: {
            //y轴线的配置
            show: true, //是否展示
            lineStyle: {
              color: '#E4E4E4', //y轴线的颜色（若只设置了y轴线的颜色，未设置y轴文字的颜色，则y轴文字会默认跟设置的y轴线颜色一致）
              width: 1, //y轴线的宽度
              type: 'dashed' //y轴线为实线
            }
          },
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              interval: 1, // 设置为1表示隔一个标签显示一个标签，从而调整分割线的间距
              color: '#333333', //Y轴内容文字颜色
              fontSize: 10 // 设置y轴标题文字大小为25
            }
          },
          // y轴分割线
          splitLine: {
            show: true, // 显示分隔线
            lineStyle: {
              type: 'dashed', // 设置分隔线为虚线
              color: '#E4E4E4'
            }
          },
          min: '0',
          max: type == 1 ? '300' : '30'
          // 设置y轴分割段数
          // splitNumber: 6
          // 设置y轴刻度间隔，例如设置为1，则刻度间隔为1
          // interval: 1
        },
        series: [
          {
            data: this.chart1Flag ? this.chartArr1.map((item) => item.num) : this.chart1DefaultData,
            type: 'line',
            // 面积颜色
            areaStyle: {
              color: '#DCE9FE'
            },
            markPoint: {
              symbol: 'circle' // 设置拐点小圆点
              // 其他配置项...
            },
            symbolSize: 0 // 设置拐点小圆点大小
          }
        ]
      }

      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },

    // 切换年数据（折线图1）
    checkYearEchart1() {
      this.chart1XAxis = []
      this.chart1Series = []
      this.isActive1 = true
      this.isActive2 = false
      this.isActive3 = false
      this.chart1 = 3
      this.getUserCurveChart(this.chart1)
    },
    // 切换月数据（折线图1）
    checkMonthEchart1() {
      this.chart1XAxis = []
      this.chart1Series = []
      this.isActive1 = false
      this.isActive2 = false
      this.isActive3 = true
      this.chart1 = 1
      this.getUserCurveChart(this.chart1)
    },
    // 切换日数据（折线图1）
    checkDayEchart1() {
      this.chart1XAxis = []
      this.chart1Series = []
      this.isActive1 = false
      this.isActive2 = true
      this.isActive3 = false

      this.chart1 = 2
      this.getUserCurveChart(this.chart1)
    },
    // 获取用户数据 (3年 2月 1日)
    async getUserCurveChart(type) {
      const res = await getVisitRequirementResult({
        type
      })
      if (res.resultCode == 200) {
        this.chart1XAxis = []
        this.chartArr1 = []
        // 如果折线图数据为空
        if (!res.data) {
          this.chart1Flag = false
          this.chart1XAxis = [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ]
          this.chartArr1 = this.chart1DefaultData
        } else {
          this.chart1Flag = true
          // 所有的数据
          this.chartArr1 = res.data
          // console.log(  this.chartArr1);

          res.data.forEach((obj) => {
            this.chart1XAxis.push(obj.time)
            this.chart1Series.push(obj.num)
          })
          if (type == 1) {
            this.chart1XAxisShowMd = []
            this.chart1XAxis.forEach((mon) => {
              // this.chart1XAxisShowMd.push(month.split('-')[1])
              const month = mon.split('-')[1]
              // 使用replace方法和正则表达式去除月份和日期前的零
              const formattedMonth = month.replace(/^0+/, '')
              // 组合成新的日期格式
              this.chart1XAxisShowMd.push(formattedMonth + '月')
            })
          } else if (type == 2) {
            this.chart1XAxisShowMd = []
            this.chart1XAxis.forEach((md) => {
              const [year, month, day] = md.split('-')
              // 使用replace方法和正则表达式去除月份和日期前的零
              const formattedMonth = month.replace(/^0+/, '')
              const formattedDay = day.replace(/^0+/, '')
              // 组合成新的日期格式
              this.chart1XAxisShowMd.push(`${formattedMonth}/${formattedDay}`)
            })
          }
        }
        // 折线图1数据
        this.renderEcharts1()
      }
    }
  }
}
</script>
  
  <style lang="less" scoped>
.active {
  border: 1px 0px 0px 0px;
  background: #85b6ff;
  color: #fff;
  cursor: pointer;
}
.workbench {
  margin-right: 8px;
  width: 100%;
  height: 100%;
  background: #f6f8f9;
  box-sizing: border-box;
  overflow-y: auto;

  .dataEcharts {
    height: 100%;
    width: 100%;
    .userData {
      background: #fff;
      height: calc(100% - 40px);
      padding: 20px 20px 10px 20px;
      border-radius: 8px;
      .data_title {
        display: flex;
        justify-content: space-between;
        .data {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          color: #151515;
        }
        .date {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.8px;
          color: #333;
          display: flex;
          div {
            display: inline-block;
            width: 27px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            border-radius: 4px;
            cursor: pointer;
          }
          // .active {
          //   border: 1px 0px 0px 0px;
          //   background: #85b6ff;
          //   color: #fff;
          //   cursor: pointer;
          // }
          .year {
          }
          .month {
            // background: #85b6ff;
            // color: #fff;
          }
          .day {
            width: 45px;
          }
        }
      }
      .data_echarts {
        width: 100%;
        height: 320px;
        overflow: hidden;
      }
    }
  }
}
</style>
  